import "./scss/bs-startup-names.scss";
import Vue from "vue";
import axios from "axios";
// import VueGtm from "vue-gtm";
// import VueAnalytics from "vue-analytics";

const generateTextAlts = ["Generate!", "Hit me!", "Another one!", "I'm all in!", "Gimme more!", "Do it again!", "Let's go!"];
let generateTextIndex = 0;
const questionImgPath = "images/ic_question.svg";
const crossImgPath = "images/ic_cross.svg";
const lockImgPath = "images/ic_lock.svg";
const unlockImgPath = "images/ic_unlock.svg";
// const generateButton = document.getElementById("generate");

// Vue.use(VueGtm, {
//   id: "GTM-5G7SCT3",
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: true, // Whether or not display console logs debugs (optional)
// });

// Vue.use(VueAnalytics, {
//   id: "UA-66098685-2",
//   debug: {
//     enabled: true
//   }
// });

Vue.component("copy-button", {
  template: `
  <img class="copy-icon" id="copy-icon" src="/images/ic_copy.svg" alt="Copy icon" @click="copy">
  `,
  methods: {
    copy: function() {
      copyToClipboard();
    }
  }
});

Vue.component("generated-word", {
  template: `
  <div class="word-container" @mouseover="onWordHover" @mouseleave="onWordLeave" @click="toggleWordLock">
    <img class="lock-icon-large" v-if="showWordLock" :src="wordIcon" alt="Lock icon">
    <a v-text="word" :class="{ 'locked-word' : isWordLocked }"></a>
  </div>
  `,
  data: function() {
    return {
      showWordLock: false,
      isWordLocked: false,
      word1con: lockImgPath,
    };
  },
  methods: {
    onWordHover: function() {
      this.showWordLock = true;
    },
    onWordLeave: function() {
      if (!this.isWordLocked) {
        this.showWordLock = false;
      }
    },
    toggleWordLock: function() {
      this.isWordLocked = !this.isWordLocked;
      this.wordIcon = this.isWordLocked ? lockImgPath : unlockImgPath;
    },
  },
  props: ["word"]
});

const vm = new Vue({
  el: "#app",
  data: {
    generateText: generateTextAlts[generateTextIndex],
    generateLoading: false,
    showWaitingText: true,
    showHowTo: false,
    showAbout: false,
    firstWord: null,
    secondWord: null,
    originalFirstWord: null,
    originalSecondWord: null,
    showWord1Lock: false,
    showWord2Lock: false,
    isWord1Locked: false,
    isWord2Locked: false,
    howToIcon: questionImgPath,
    word1Icon: lockImgPath,
    word2Icon: lockImgPath,
    showCopied: false
  },
  methods: {
    generateName: function () {
      generateName();
    },
    toggleHowTo: function() {
      this.showHowTo = !this.showHowTo;
      //fire tag if opening
      // this.$gtm.trackEvent({
      //   category: "Button",
      //   action: "Click",
      //   label: "How to click",
      //   value: this.showHowTo
      // });
      // this.$ga.event("Button", "Click", "How to Click", this.showHowTo);
      this.howToIcon = this.showHowTo ? crossImgPath : questionImgPath;
    },
    toggleAbout: function() {
      this.showAbout = !this.showAbout;
    },
    onWord1Hover: function() {
      this.showWord1Lock = true;
    },
    onWord1Leave: function() {
      if (!this.isWord1Locked) {
        this.showWord1Lock = false;
      }
    },
    toggleWord1Lock: function() {
      this.isWord1Locked = !this.isWord1Locked;
      this.word1Icon = this.isWord1Locked ? lockImgPath : unlockImgPath;
    },
    onWord2Hover: function() {
      this.showWord2Lock = true;
    },
    onWord2Leave: function() {
      if (!this.isWord2Locked) {
        this.showWord2Lock = false;
      }
    },
    toggleWord2Lock: function() {
      this.isWord2Locked = !this.isWord2Locked;
      this.word2Icon = this.isWord2Locked ? lockImgPath : unlockImgPath;
    },
    misspell: function() {
      misspellName();
    },
    afterCopiedEnter: function() {
      this.showCopied = false;
    }
  },
  // mounted() {
  //   console.log("mounted");
  //   this.$gtm.trackView("Home", "/");
  // }
});

/**
 * Makes an API call to get a new name. 
 * 
 */
function generateName() {
  vm.generateLoading = true;

  //fetch data from API
  axios.get("https://d7l2q1um44.execute-api.ap-south-1.amazonaws.com/dev/get-name-pair", {
    params: {
      word1: vm.isWord1Locked ? vm.firstWord : null,
      word2: vm.isWord2Locked ? vm.secondWord : null
    }
  })
    .then(response => {
      console.log(response);
      vm.firstWord = formatGeneratedWord(response.data.firstWord);
      vm.secondWord = formatGeneratedWord(response.data.secondWord);
      vm.originalFirstWord = vm.firstWord;
      vm.originalSecondWord = vm.secondWord;

      //set waiting text
      if (vm.firstWord !== "" && vm.secondWord !== "") {
        vm.showWaitingText = false;
      }

      vm.generateLoading = false;
      updateGenerateButtonText();
    })
    .catch(error => console.error(error));
  

  //fake the api call delay
  // sleep(1000).then(() => {
  //   vm.generateLoading = false;
  //   console.log(generateButton);
  //   updateGenerateButtonText();
  // });
}

function misspellName() {
  vm.generateLoading = true;

  axios.get("https://d7l2q1um44.execute-api.ap-south-1.amazonaws.com/dev/misspell-name", {
    params: {
      word1: vm.originalFirstWord,
      word2: vm.originalSecondWord
    }
  })
    .then(response => {
      console.log(response);
      vm.firstWord = formatGeneratedWord(response.data.firstWord);
      vm.secondWord = formatGeneratedWord(response.data.secondWord);

      vm.generateLoading = false;
      updateGenerateButtonText();
    })
    .catch(error => console.error(error));
}

function formatGeneratedWord(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function updateGenerateButtonText() {
  generateTextIndex++;
  if (generateTextIndex === generateTextAlts.length) {
    generateTextIndex = 0;
  }
  vm.generateText = generateTextAlts[generateTextIndex];
}

function copyToClipboard() {
  var generatedName = vm.firstWord + " " + vm.secondWord;
  navigator.clipboard.writeText(generatedName).then(function() {
    vm.showCopied = true;
    console.log("Async: Copying to clipboard was successful!");
  }, function(err) {
    console.error("Async: Could not copy text: ", err);
  });
}

// function sleep(time) {
//   return new Promise((resolve) => setTimeout(resolve, time));
// }

// window.onload = function() {
//   generateButton.onclick = generateName;
// };
